@font-face {
  font-family: 'WorkSans';
  src: url('../../assets/fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
}
html {
    box-sizing: border-box;
  }
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  body {
    font-family: "WorkSans", "Roboto", sans-serif;
  }
  .login {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("./../../assets/background.jpeg") no-repeat center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
  }
  .react-form {
    width: 30%;
    border-radius: 5px;
    background: rgba(34, 34, 34, 0.6);
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    padding: 50px;
    text-align: center;
    position: absolute;  
    top: 40%;
    left: 0;   
    right: 0;
    margin: auto;  
  }
  h1 {
    color: #333;
    font-size: 24px;
    margin-top: 0;
  }
  input {
    font-family: "WorkSans", "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    border: none;
    border-bottom: 1px solid #dfbf6d;
    background: none;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    outline: none;
    color:#b3b3b3;
    padding-top: 10px;
  }
  .btn {
    background-color: #dfbf6d;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    padding: 15px 30px 15px 35px;
    transition: 300ms ease all;
    letter-spacing: 2px;
    text-align: center;
    width: fit-content;
    border: 0px;
  }
  .btn:hover{
    background-color: #000;
  }
  .Logo{
    width:20%;
    position: absolute;  
    top: 3em;
    left: 0;   
    right: 0;
    margin: auto;  
  }
  .form-group{
    padding-bottom: 30px;
  }
  label{
    color:#fff;
    font-family: "WorkSans", "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 2px;

  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #222222;
    color: white;
    text-align: center;
    padding:10px;
    font-family: "WorkSans", "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    letter-spacing: 8px;
    font-size: 10px;
  }
  .alert-danger{
    padding-top:5px;
    color:#dfbf6d;
  }

  @media all and (max-width: 600px){
    .Logo{
        width:60%;
        top: 2em;
      }
    .react-form {
        width:80%;
        top: 40%;
    }
}

.space-back{
  padding-top: 20px;
  cursor: pointer;
}
