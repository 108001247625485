.roots {
    flex-grow: 1;
    margin: auto;
    padding: 20px;
  }

.cards {
    max-width: 300;
    margin:  20px;
    background-color: #f5f5f5;
}

.avatars {
    width: 300px !important;
    height: 345px !important;
    margin: auto;
    margin: 20px;
    border-radius: 2% !important;
}

.headers {
    text-align: center;
}

.names {
    text-align: center;
    margin: 10px;
    font-weight: 400px;
 }

 .socials {
    text-align: center;
  }

  .botones {
    background-color: #dfbf6d;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    transition: 300ms ease all;
    letter-spacing: 2px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    border: 0px;
    margin-top: 20px;
}