@import '@fontsource/barlow-semi-condensed';

.rootsmaps {
    flex-grow: 1;
    margin: auto;
  }

.cards {
    max-width: 300;
    margin:  20px;
    background-color: #f5f5f5;
}

.avatars {
    width: 250px !important;
    height: 250px !important;
    margin: auto;
    margin: 20px,
}

.headers {
    text-align: center;
}

.names {
    text-align: center;
    margin: 10px;
    font-weight: 400px;
 }

 .socials {
    text-align: center;
  }

   .info-image {
    width: 210px;
    height: 148px;
    padding: 0 10px 0 0;
}
.info-status {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #484848;
    text-transform: uppercase;
    letter-spacing: 0.28px;
    margin: 16px 0 16px 12px;
}
.pin-sold {
    background-color: #ff1134;
}
.info-address {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.26px;
    color: #484848;
    text-transform: uppercase;
    padding: 0 13px;
}
.info-extras li {
    display: inline-block;
    vertical-align: middle;
    margin-left: -4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0.22px;
    color: #484848;
    padding-left: 12px;
    margin-left: 9px;
    position: relative;
}
.info-price {
    display: block;
    width: 100%;
    height: 59px;
    font-family: "Barlow Semi Condensed", sans-serif;
    background: #282828;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1.4px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 13px;
}
a {
    text-decoration: none;
  }

 .titleMap {
    font-size: 90px !important;
    font-weight: 600 !important;
    line-height: 90px !important;
    letter-spacing: -2.25px !important;
    margin: 37px 0 50px !important;
    text-align: center;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 30px;
    line-height: 40px;
    color: #1b1b1b;
    text-transform: uppercase;
}

.titleMap span {
    display: inline-block;
    text-align: right;
    position: relative;
}
.titleMap b {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 36px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: 0.36px !important;
    margin-top: 6px;
    display: block;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -1.8px;
    color: #e51937;
    position: relative;
}
.titleMap span .title-line {
    width: calc(100% - 94px);
    bottom: 15px;
    left: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #d7d7d7;
}
.buttonRange {
    float: left;
    width: 188px;
    height: 77px;
    position: relative;
    background: #e51937 !important;
    color: #fff !important;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .buttonRange :hover{
    background: #555555 !important;
  }
  .pullRight{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }