.Container-Principal{
    display: flex;
    padding:80px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Container-Principal > * {
    margin: 10px;
  }

.folder-container{
    width:100%;
    padding:20px;
}
.folder-container div{
    padding:5px;
}
.visor-container{
    width:100%;
    padding:20px;
}
.FolderOpenIcon{
    margin-left: 5px;
    margin-right: 5px;
}
.FolderTree{
    text-align: initial;

}
.FileIcon{
    margin-left: 5px;
    margin-right: 5px;
}
.FolderIcon{
    margin-left: 5px;
    margin-right: 5px;
}
.TreeNode:hover{
    background-color: #f6f6f6;
}
.TreeNode{
    padding:10px;
}
.editableNameContainer{
    cursor:pointer;
}
.typeIconContainer{
    color:#dfbf6d;
}
@media (max-width: 767px) {
    .Container-Principal {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding:10px;
  }
}

#ResumeContainer {
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}

.btn_prev {
    background-color: #dfbf6d;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    padding: 7px 10px 7px 10px;
    transition: 300ms ease all;
    letter-spacing: 8px;
    text-align: center;
    width: fit-content;
    border: 0px;
    margin:5px;
  }
  .btn:hover{
    background-color: #000;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }