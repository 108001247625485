.logo-dark{
    width:200px;
}

/* Estilos para el contenedor del menú */
.menu-container {
    text-align: center;
    padding-top: 20px;
  }
  
  /* Estilos para los elementos del menú */
  .menu-item {
    display: inline-block;
    margin: 0 10px;
    color: #000;
    text-decoration: none;
  }
  
  /* Estilos para el separador "|" */
  .menu-separator {
    color: #dfbf6d;
  }

  .menuFirst{
    flex: 0 0 60%; /* El div ocupará el 80% del ancho disponible, pero no cambiará su tamaño */
    padding: 10px;
  }
  .menuSecond{
    flex: 0 0 20%; /* El div ocupará el 80% del ancho disponible, pero no cambiará su tamaño */
    padding: 10px;
  }
  .menurthirt{
    flex: 0 0 20%; /* El div ocupará el 80% del ancho disponible, pero no cambiará su tamaño */
    padding: 10px;    
  }
  .menurthirtMobile{
    flex: 0 0 10%; /* El div ocupará el 80% del ancho disponible, pero no cambiará su tamaño */
    padding: 10px;    
    display: none;
  }
  
  .menuContainer{
    display: flex;
  }
  @media (max-width: 768px) {
    .menu-item,
    .menu-separator {
      display: block;
      margin: 5px 0;
    }
    .menurthirt{
      display: none;
    }
    .menurthirtMobile{
      display:block;
      display: flex;
      align-items: flex-start; /* Alinea al 80% desde la parte superior */
      margin-top: 10%; 
      justify-content: center; /* Centra horizontalmente el contenido */

    }
    .menu-separator {
        display: none;
      }

      .avatars-menu {
        width: 100px !important;
        height: 118px !important;
        margin: 0px;
        border-radius: 2% !important;
    }
    .menu-container{
      display: none;
    }
    .menuFirst{
      flex: 0 0 60%;
    }
    .menuSecond{
      flex: 0 0 20%;
    }
    .logo-dark{
      width:150px;
    }
   
  }

  .avatars-menu {
    width: 150px ;
    height: 150px;
    margin: 0px;
    border-radius: 2% ;
}

.announces{
  background-color: #dfbf6d;
  color:#fff;
  font-family: "WorkSans", "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
  padding: 20px;
  font-size: 20px;
}

.slide {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 0 20px;
}

.mobile-menu-open {
  display: block;
  /* Agrega estilos para mostrar el menú cuando está abierto */
}
  .menu2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.3s ease;
    /* Aplica estilos para el efecto de apertura */
  }
    .start-icon {
      color: #dfbf6d;
      font-weight: 700;
    }

    .menu-item2 {
      padding: 3px 0;
      margin-left:10px;
      /* Agrega estilos para los elementos del menú */
    }
  
